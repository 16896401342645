<template>
  <div
    class="fixed z-40 top-0 bottom-0 left-0 right-0 h-full w-full bg-black bg-opacity-50 flex justify-end"
  >
    <div class="w-3/4 h-full cursor-pointer" @click="$emit('close')"></div>
    <div class="w-1/4 h-full bg-white rounded-l-2xl p-10 overflow-auto">
      <div class="flex items-center justify-between">
        <h1 class="text-black font-bold text-xl">{{ action }} Removal Form</h1>
        <div
          class="bg-red-500 bg-opacity-30 p-5 rounded-full cursor-pointer"
          @click="$emit('close')"
        >
          <CloseIcon />
        </div>
      </div>
      <ValidationObserver v-slot="{ invalid }" v-if="step === 1">
        <form @submit.prevent="step = 2" class="text-left mt-10">
          <ValidationProvider
            name="Corpse Tag Number"
            rules="required"
            v-slot="{ errors }"
          >
            <div class="mb-5">
              <label>Corpse Tag Number</label>
              <input
                type="number"
                class="border-transparent focus:border-transparent focus:ring-0 focus:outline-none bg-gray-100 py-2 px-3 rounded-md w-full"
                :class="errors[0] ? 'border border-red-400' : ''"
                placeholder="98877"
                :disabled="action === 'Edit'"
                v-model="removal.corpse_tag"
              /><br />
            </div>
          </ValidationProvider>
          <ValidationProvider
            name="Hospital/Place of Removal"
            rules="required"
            v-slot="{ errors }"
          >
            <div class="mb-5">
              <label>Hospital/Place of Removal</label>
              <input
                type="text"
                class="border-transparent focus:border-transparent focus:ring-0 focus:outline-none bg-gray-100 py-2 px-3 rounded-md w-full"
                :class="errors[0] ? 'border border-red-400' : ''"
                placeholder="St John’s Hospital, Ikeja"
                v-model="removal.place_of_removal"
              /><br />
            </div>
          </ValidationProvider>
          <ValidationProvider
            name="Location From"
            rules="required"
            v-slot="{ errors }"
          >
            <div class="mb-5">
              <label>Location From</label>
              <input
                type="text"
                class="border-transparent focus:border-transparent focus:ring-0 focus:outline-none bg-gray-100 py-2 px-3 rounded-md w-full"
                :class="errors[0] ? 'border border-red-400' : ''"
                placeholder="St John’s Hospital, Ikeja"
                v-model="removal.location_from"
              /><br />
            </div>
          </ValidationProvider>
          <ValidationProvider
            name="Location To"
            rules="required"
            v-slot="{ errors }"
          >
            <div class="mb-5">
              <label>Location To</label>
              <input
                type="text"
                class="border-transparent focus:border-transparent focus:ring-0 focus:outline-none bg-gray-100 py-2 px-3 rounded-md w-full"
                :class="errors[0] ? 'border border-red-400' : ''"
                placeholder="St John’s Hospital, Ikeja"
                v-model="removal.location_to"
              /><br />
            </div>
          </ValidationProvider>
          <h3 class="my-3">Information of Family Contact</h3>
          <ValidationProvider
            name="Family Contact Name"
            rules="required"
            v-slot="{ errors }"
          >
            <div class="mb-5">
              <label>Name</label>
              <input
                type="text"
                class="border-transparent focus:border-transparent focus:ring-0 focus:outline-none bg-gray-100 py-2 px-3 rounded-md w-full"
                :class="errors[0] ? 'border border-red-400' : ''"
                placeholder="Jack Doe"
                v-model="removal.family_contact_name"
              /><br />
            </div>
          </ValidationProvider>
          <ValidationProvider
            name="Contact Number"
            rules="required"
            v-slot="{ errors }"
          >
            <div class="mb-5">
              <label>Contact Number</label>
              <input
                type="number"
                class="border-transparent focus:border-transparent focus:ring-0 focus:outline-none bg-gray-100 py-2 px-3 rounded-md w-full"
                :class="errors[0] ? 'border border-red-400' : ''"
                placeholder="090110000111"
                v-model="removal.family_contact_phone_number"
              /><br />
            </div>
          </ValidationProvider>
          <ValidationProvider
            name="Family Contact Address"
            rules="required"
            v-slot="{ errors }"
          >
            <div class="mb-5">
              <label>Contact Address</label>
              <input
                type="text"
                class="border-transparent focus:border-transparent focus:ring-0 focus:outline-none bg-gray-100 py-2 px-3 rounded-md w-full"
                :class="errors[0] ? 'border border-red-400' : ''"
                placeholder="Lagos, Nigeria"
                v-model="removal.family_contact_address"
              /><br />
            </div>
          </ValidationProvider>
          <button
            class="w-full mt-8 text-white py-3 rounded-md bg-opacity-80 transition-all hover:bg-opacity-100 disabled:cursor-not-allowed"
            :class="invalid ? 'bg-gray-300' : 'bg-primary'"
            :disabled="invalid"
          >
            Continue
          </button>
        </form>
      </ValidationObserver>
      <ValidationObserver v-slot="{ invalid }" v-else>
        <form @submit.prevent="createRemoval" class="text-left mt-10">
          <h3 class="my-3">Removed By</h3>
          <ValidationProvider
            name="Name of Driver"
            rules="required"
            v-slot="{ errors }"
          >
            <div class="mb-5">
              <label>Name of Driver</label>
              <input
                type="text"
                class="border-transparent focus:border-transparent focus:ring-0 focus:outline-none bg-gray-100 py-2 px-3 rounded-md w-full"
                :class="errors[0] ? 'border border-red-400' : ''"
                placeholder="Jack Doe"
                v-model="removal.removed_by_name_of_driver"
              /><br />
            </div>
          </ValidationProvider>
          <ValidationProvider
            name="Driver Phone Number"
            rules="required"
            v-slot="{ errors }"
          >
            <div class="mb-5">
              <label>Driver Phone Number</label>
              <input
                type="number"
                class="border-transparent focus:border-transparent focus:ring-0 focus:outline-none bg-gray-100 py-2 px-3 rounded-md w-full"
                :class="errors[0] ? 'border border-red-400' : ''"
                placeholder="0901122334455"
                v-model="removal.removed_by_phone_number"
              /><br />
            </div>
          </ValidationProvider>
          <ValidationProvider
            name="Ambulance No"
            rules="required"
            v-slot="{ errors }"
          >
            <div class="mb-5">
              <label>Ambulance No</label>
              <input
                type="number"
                class="border-transparent focus:border-transparent focus:ring-0 focus:outline-none bg-gray-100 py-2 px-3 rounded-md w-full"
                :class="errors[0] ? 'border border-red-400' : ''"
                placeholder="990"
                v-model="removal.removed_by_ambulance_no"
              /><br />
            </div>
          </ValidationProvider>
          <ValidationProvider
            name="Removal Date"
            rules="required"
            v-slot="{ errors }"
          >
            <div class="mb-5">
              <label>Date</label>
              <input
                type="date"
                class="border-transparent focus:border-transparent focus:ring-0 focus:outline-none bg-gray-100 py-2 px-3 rounded-md w-full"
                :class="errors[0] ? 'border border-red-400' : ''"
                placeholder="St John’s Hospital, Ikeja"
                v-model="removal.removed_by_date"
              /><br />
            </div>
          </ValidationProvider>
          <h3 class="my-3">Removal Authorized by</h3>
          <ValidationProvider name="Name" rules="required" v-slot="{ errors }">
            <div class="mb-5">
              <label>Name</label>
              <input
                type="text"
                class="border-transparent focus:border-transparent focus:ring-0 focus:outline-none bg-gray-100 py-2 px-3 rounded-md w-full"
                :class="errors[0] ? 'border border-red-400' : ''"
                placeholder="Jack Doe"
                v-model="removal.removal_authorized_by_name"
              /><br />
            </div>
          </ValidationProvider>
          <ValidationProvider
            name="Contact Number"
            rules="required"
            v-slot="{ errors }"
          >
            <div class="mb-5">
              <label>Contact Number</label>
              <input
                type="number"
                class="border-transparent focus:border-transparent focus:ring-0 focus:outline-none bg-gray-100 py-2 px-3 rounded-md w-full"
                :class="errors[0] ? 'border border-red-400' : ''"
                placeholder="090110000111"
                v-model="removal.removal_authorized_by_phone_number"
              /><br />
            </div>
          </ValidationProvider>
          <ValidationProvider name="Date" rules="required" v-slot="{ errors }">
            <div class="mb-5">
              <label>Date</label>
              <input
                type="date"
                class="border-transparent focus:border-transparent focus:ring-0 focus:outline-none bg-gray-100 py-2 px-3 rounded-md w-full"
                :class="errors[0] ? 'border border-red-400' : ''"
                placeholder="Date"
                v-model="removal.removal_authorized_by_date"
              /><br />
            </div>
          </ValidationProvider>
          <button
            class="w-full mt-8 text-white py-3 rounded-md bg-opacity-80 transition-all hover:bg-opacity-100 disabled:cursor-not-allowed"
            :class="invalid || submitting ? 'bg-gray-300' : 'bg-primary'"
            :disabled="invalid || submitting"
          >
            Create Removal Log
          </button>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>
<script>
import { createRemoval as newRemoval, editRemoval } from "@/services/enquiry";

export default {
  name: "AddRemovalModal",
  components: {
    CloseIcon: () => import("@/assets/icons/CloseIcon.vue"),
  },
  props: {
    newRemoval: {
      required: false,
      type: Object,
    },
    action: {
      required: true,
      type: String,
      default: "Add",
    },
  },
  data() {
    return {
      removal: {
        corpse_tag: "",
        removed_by_name_of_driver: "",
        removed_by_phone_number: "",
        removed_by_ambulance_no: "",
        removed_by_date: "",
        removal_authorized_by_name: "",
        removal_authorized_by_phone_number: "",
        removal_authorized_by_date: "",
        removal_authorized_by_signature: "",
        place_of_removal: "",
        location_from: "",
        location_to: "",
        family_contact_name: "",
        family_contact_phone_number: "",
        family_contact_address: "",
      },
      submitting: false,
      step: 1,
    };
  },
  methods: {
    async createRemoval() {
      this.removal.removal_authorized_by_signature =
        this.removal.removal_authorized_by_name;
      try {
        if (this.action === "Edit") {
          await editRemoval(this.removal);
          this.$toast.success("Removal updated successfully.");
        } else {
          await newRemoval(this.removal);
          this.$toast.success("Removal created successfully.");
        }
        this.$emit("created");
      } catch (error) {
        this.$toast.error(error.response.data.message || error);
        console.log(error.response.data.message);
      }
    },
  },
  mounted() {
    if (this.action === "Edit") {
      this.removal = this.newRemoval;
    }
  },
};
</script>
